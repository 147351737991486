












































import { Vue, Component, Watch } from "vue-property-decorator";
import { organisationModule } from "@/store/modules/organisation";
import { IOrgUnitNode } from "@/models/models";
import Utils from "@/common/utils/utils";

@Component({})
export default class OrganizationDetails extends Vue {
  nodesToDisplay: IOrgUnitNode[] = [];

  created() {
    this.nodesToDisplay.push(this.organisationUnitTreeRoot);
  }

  @Watch("organisationUnitTree")
  onOrganisationUnitTreeChanged(val: IOrgUnitNode[], old: IOrgUnitNode[]) {
    if (val && val.length > 0) {
      this.updateOrgUnitNode(val[0]);
    }
  }

  updateOrgUnitNode(node: IOrgUnitNode) {
    if (this.nodesToDisplay.some((x) => x.Id === node.Id)) {
      const index = this.nodesToDisplay.findIndex((x) => x.Id === node.Id);
      this.nodesToDisplay.splice(index, 1, node);
    }

    if (node.Children && node.Children.length !== 0) {
      for (const n of node.Children) {
        this.updateOrgUnitNode(n);
      }
    }
  }

  get organisationUnitTree() {
    return organisationModule.getOrgUnitTree;
  }

  get organisationUnitTreeRoot() {
    return organisationModule.getOrgUnitTree[0];
  }

  // This has to be done to access Utils in the template
  get utils() {
    return Utils;
  }

  rowClass(node: IOrgUnitNode): string {
    const classes: string[] = ["organization-details-table-row"];
    const level = this.getLevel(node);

    if (this.selectedRowID === node.Id) {
      classes.push("is-selected");
    }

    if (node.Children && node.Children.length !== 0 && level < 2) {
      classes.push("has-children");

      if (this.nodesToDisplay.includes(node.Children[0])) {
        classes.push("is-open");
      }
    }

    classes.push(`is-depth-${level}`);

    return classes.join(" ");
  }

  shouldDisplayNode(node: IOrgUnitNode): boolean {
    if (!node.ParentId) {
      return true;
    }
    return this.organisationUnitTreeRoot.Children.some(child => child.Id === node.Id);
  }

  showExpandArrow(node: IOrgUnitNode): boolean {
    return this.shouldDisplayNode(node) && node.Children && node.Children.length > 0;
  }

  toggleNode(node: IOrgUnitNode, index: number) {
    if (!this.shouldDisplayNode(node)) {
      return;
    }
    const children = node.Children;
    if (!children || !children.length) return;

    if (this.isExpanded(node, index)) {
      // Remove visible children and their descendants
      let removeCount = 0;

      while (
        index + 1 + removeCount < this.nodesToDisplay.length &&
        this.isDescendant(node, this.nodesToDisplay[index + 1 + removeCount])
      ) {
        removeCount++;
      }

      this.nodesToDisplay.splice(index + 1, removeCount);
    } else {
      // Add children
      this.nodesToDisplay.splice(index + 1, 0, ...children);
    }
  }

  isDescendant(ancestor: IOrgUnitNode, node: IOrgUnitNode): boolean {
    let currentNode = node;
    while (currentNode) {
      if (currentNode === ancestor) return true;
      currentNode = this.nodesToDisplay.find(
        (n) => n.Children && n.Children.includes(currentNode)
      );
    }

    // In the current usage within the toggleNode method, isDescendant never returns false.
    return false;
  }

  getLevel(node: IOrgUnitNode): number {
    let level = 0;
    let currentNode = node;
    while (currentNode) {
      currentNode = this.nodesToDisplay.find(
        (n) => n.Children && n.Children.includes(currentNode)
      );
      level++;
    }
    return level;
  }

  isExpanded(node: IOrgUnitNode, index: number): boolean {
    const children = node.Children;
    if (!children || !children.length) return false;

    return (
      this.nodesToDisplay[index + 1] &&
      this.nodesToDisplay[index + 1].Id === children[0].Id
    );
  }

  get isLoading() {
    return organisationModule.isLoading;
  }

  get selectedRowID() {
    return organisationModule.orgUnit.Id;
  }

  setOrgUnit(node: IOrgUnitNode) {
    organisationModule.setOrgUnit({
      Id: node.Id,
      Name: node.Name,
      CostCenter: node.CostCenter,
      Children: node.Children,
      Emissions: node.Emissions,
    });
  }
  clearOrgUnit() {
    organisationModule.setOrgUnit(organisationModule.orgUnits[0]);
  }

}
